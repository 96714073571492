<template>
  <div class="message">
    <title-top>消息</title-top>
    <div class="row flex-around font28 f-666666 bg-white h80">
      <div class="column align-center h80" style="justify-content: flex-end;" @click="onTab(0)">
        <span class="tapname" :class="{active:currentIndex == 0}">系统消息</span>
        <span class="underline mt16" :class="{active2:currentIndex == 0}"></span>
      </div>
      <div
        class="column align-center h80 f-666666"
        style="justify-content: flex-end;"
        @click="onTab(1)"
      >
        <span class="tapname" :class="{active:currentIndex == 1}">个人消息</span>
        <span class="underline mt16" :class="{active2:currentIndex == 1}"></span>
      </div>
    </div>
    <div v-if="currentIndex == 0" v-for="(item,index) in list1" :key="index">
      <span class="row flex-center font22 f-1A1A1A h80">{{item.created_at | formatHs}}</span>
      <div class="column whitescreen" style="width: 9.146667rem;margin-bottom: 0;">
        <p class="font28 f-1A1A1A ellipsis ml25 mr25 mt20 mb20 fontwb">{{item.title}}</p>
        <p class="font24 f-333333 ellipsis ml25 mr25 mb20">{{item.content}}</p>
        <div class="column font24 f-333333 author-date mr25 mb20">
          <span>{{item.signature}}</span>
          <span>{{item.created_at | formatYear}}</span>
        </div>
      </div>
    </div>
    <div v-if="currentIndex == 1" v-for="(item,index) in list2" :key="index">
      <div class="row align-center bg-white">
        <div class="row ml25 mr25 mt25 mb20">
          <img class="imgsize88 br50" src="~images/gonggao_icon.png" alt />
          <span class="hot-circle br50" v-if="item.read == false"></span>
        </div>
        <div class="flex1 column mt25 mb20 mr25">
          <div class="row between">
            <span class="font28 f-1A1A1A">{{item.tag == 'balance_arrival'?'奖励到账':'认证结果'}}</span>
            <span class="font20 f-999999">{{item.created_at | formatDate}}</span>
          </div>
          <p class="font24 f-999999 mt20">{{item.content}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {formatDate} from 'common/util'
import TitleTop from "@/components/common/title/TitleTop";
import { announcements, notifications,mark_all_as_read } from "network/igou";
export default {
  name: "Message",
  data() {
    return {
      currentIndex: this.$route.query.index || 0,
      list1: [],
      list2: [],
      page: 1
    };
  },
  mounted() {
    if (this.currentIndex == 0) {
      this.get_announcements();
    } else {
      this.get_notifications();
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
    formatHs(time) {
      var date = new Date(time);
      return formatDate(date, 'hh:mm');
    },
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd');
    }
  },
  methods: {
    fn_mark_all_as_read() {
      mark_all_as_read().then(res=>{});
    },
    onTab(index) {
      if (this.currentIndex == index) return;
      this.currentIndex = index;
      if (index == 0) {
        this.get_announcements();
      } else {
        this.get_notifications();
      }
    },
    get_announcements() {
      announcements(1, 100).then(res => {
        console.log(res);
        this.list1 = res.data;
      });
    },
    get_notifications() {
      notifications(1, 100).then(res => {
        console.log(res);
        this.list2 = res.data;
        this.fn_mark_all_as_read();
      });
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.active {
  color: #f62341;
}
.active2 {
  background-color: #f62341;
}
.underline {
  height: 0.026667rem;
  width: 1.493333rem;
}
.messimg {
  width: 8.506667rem;
  height: 3.2rem;
}
.author-date {
  align-items: flex-end;
}
.hot-circle {
  width: 0.266667rem;
  height: 0.266667rem;
  background-color: red;
}

</style>
